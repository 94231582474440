import { useSandBox } from '#imports';
import { onBeforeUnmount, onMounted } from 'vue';

export const useWindowMessage = ({ onRecieveMessage = () => {} } = {}) => {
    const isSandbox = useSandBox();

    const sendMessage = (type, data = null) => {
        if (isSandbox) return;

        window.parent.postMessage({ type, data }, '*');

        console.log('FanaticsProvider: Message sent to parent:', type, data);
    };

    const recieveMessage = (event) => {
        if (event.type) {
            onRecieveMessage(event);
        } else {
            console.warn('Window message object does not have a type:', event);
        }
    };

    const addEvents = () => {
        // This is where the page listens for messages from the parent
        window.addEventListener('message', recieveMessage);
    };

    const removeEvents = () => {
        window.removeEventListener('message', recieveMessage);
    };

    onMounted(addEvents);
    onBeforeUnmount(removeEvents);

    return { sendMessage, recieveMessage };
};
