import { onBeforeMount, shallowRef } from 'vue';

export const useParentSearchParams = (cb = () => {}) => {
    const params = shallowRef(null);

    onBeforeMount(() => {
        const win = window.parent || window;
        params.value = new URLSearchParams(win.location.search);

        cb(params.value);
    });

    return params;
};
